<script lang="jsx">
import TojoyBlank from '@/components/business/blank/blank'

export default {
  name: 'tojoy-infinite-list',
  components: {
    TojoyBlank
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    func: {
      type: Function
    },
    size: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      page: 1,
      flag: false
    }
  },
  computed: {
    noMore() {
      return this.flag && (this.total === this.data.length) // this.data.length % this.size != 0
    },
    disabled() {
      return this.loading || this.noMore
    }
  },
  methods: {
    handleLoad() {
      this.loading = true
      setTimeout(async() => {
        await this.func(this.page)
        this.page++
        this.flag = true
        this.loading = false
      }, 200)
    }
  },
  render(h) {
    const { handleLoad, disabled, loading, noMore, $scopedSlots } = this
    return (
      <div class="tojoy-infinite-list">
        <div
          class={['tojoy-infinite-list__section', this.data.length > 0 ? '' : 'section-nodata']}
          vInfiniteScroll={handleLoad}
          infinite-scroll-disabled={disabled}
          infinite-scroll-distance={10}
        >
          <div class="tojoy-infinite-list__wrap">
            {
              this.flag && this.data.length === 0 ? <tojoy-blank blank-data={{type: 'notdata'}} /> : ''
            }
            {
              this.data.map((item, index) => {
                return (
                  <div key={index} class="item-card">
                    {
                      $scopedSlots.item({
                        data: item
                      })
                    }
                  </div>
                )
              })
            }
          </div>
        </div>
        {
          this.loading ? <p class="nodata">加载更多…</p> : ''
        }
        {
          this.data.length  >0 && this.noMore ? <p class="nodata">没有更多了</p> : ''
        }
      </div>
    )
  }
}
</script>

<style lang="scss">
.tojoy-infinite-list {
  height: 100%;
  overflow: auto;
  .nodata {
    font-size: 14px;
    font-weight: 400;
    color: $grey;
    line-height: 14px;
    text-align: center;
    margin-top: 30px;
  }
  &__section {
    &.section-nodata {
      height: 100%;
    }
  }
  &__wrap {
    height: 100%;
    background: $white;
    // box-shadow: 0px 1px 0px 0px $linecolor;
    .item {
      &-card {
        display: flex;
        //  border-bottom: 1px solid $linecolor;
        padding: 0px 20px 0;
      }
    }
    &:last-of-type {
      border-bottom: none;
    }
  }
}
</style>
