import * as http from './http'
//  查询创新成果列表-我发起的
export const getMeSponsor = params => http.post('/innovation/web/list-me-sponsor', params)
// 查询创新成果列-看看大家的创新提案
export const getInnovation = params => http.post('/innovation/web/list', params)

//查询创新成果列表接口我审批的
export const getMeApprove = params => http.post('/innovation/web/list-me-approve', params)

//当月使用积分提交
export const postScoreSave = params => http.post('/innovation/index/save', params)
//查询当前用户创新成果积分
export const getScoreCountData = params => http.post('/innovation/index/count-data', params)
//查询当前用户积分历史
export const getScorehistorylist = params =>
  http.post('/innovation/index/list-history', params, { loading: false })

//审批说明查询 /innovation/score/detail-approve-desc
export const getApproveDesc = params => http.post('/innovation/score/detail-approve-desc', params)
//填写说明内容配置查询 /innovation/score/detail-submit-desc
export const getSubmitDesc = params => http.post('/innovation/score/detail-submit-desc', params)

//回显草稿箱数据
export const getDataDraft = params => http.post('/innovation/web/data-draft', params)
//保存草稿箱数据
export const postDataDraftSave = params => http.post('/innovation/web/save-draft', params)
//根据创新成果查询详情
export const getInnovationDetail = params => http.post('/innovation/web/detail', params)
//创新成果提案编辑提交
export const postInnovationEdit = params => http.post('/innovation/web/edit', params)
//创新成果提案新增
export const postInnovationAdd = params => http.post('/innovation/web/save', params)
//创新成果删除
export const postInnovationDelete = params => http.post('/innovation/web/delete', params)
//创新应用层面列表查询
export const getInnovationBedding = params => http.post('/innovation/form/list-bedding', params)
//创新应用类别列表查询
export const getInnovationCategory = params => http.post('/innovation/form/list-category', params)

//审批或者驳回
export const postInnovationApprove = params => http.post('/innovation/process/approve', params)
//根据创新成果主键查询审批流程操作
export const getInnovationDetailProcess = params =>
  http.post('/innovation/process/detail-process', params)
//审批委托提交
export const postInnovationEntrust = params => http.post('/innovation/process/entrust', params)
//查询审批流程模板列表
export const getInnovationProcessList = params => http.post('/innovation/process/list', params)
//撤销
export const postProcessRevoke = params => http.post('/innovation/process/revoke', params)
// 根据名称搜索组织结构
export const getOrgList = params => http.post('/org/query-by-name', params)
// 查询成果级别
export const getResultlevelList = params => http.post('/innovation/score/list-result-level', params)

//查询表单字段 /innovation/form/list-web-form
export const getFromList = params => http.post('/innovation/form/list-web-form', params)
//列表条数
export const getListCount = params => http.post('/innovation/web/list-mine-count', params)
//查询创新维度列表
export const getListDimension = params =>
  http.post('/innovation/score/list-innovate-dimension', params)
//查询成果级别
export const getQueryResultLevel = params =>
  http.post('/innovation/score/query-result-level', params)
