export const initiateTabNames = [
  {
    name: 'all',
    label: '全部',
    badge: 0
  },
  {
    name: 'submit',
    label: '待提交',
    badge: 1
  },
  {
    name: 'approval',
    label: '审批中',
    badge: 0
  },
  {
    name: 'through',
    label: '已通过',
    badge: 0
  }
]
export const approvalTabNames = [
  {
    name: 'approval',
    label: '待我审批',
    badge: 0
  },
  {
    name: 'complete',
    label: '我已完成',
    badge: 0
  }
]
export const btnConfig = [
  {
    text: '撤回',
    className: 'blue',
    code: 'revoke',
    flag: 1,
    state: [3, 4],
    type: 'primary',
    fn: null,
    show: false
  },
  {
    text: '删除',
    className: 'white',
    code: 'delete',
    flag: 1,
    state: [2, 5, 6],
    type: '',
    fn: null,
    show: false
  },
  {
    text: '编辑',
    className: 'blue',
    code: 'edit',
    flag: 1,
    state: [2, 5],
    type: 'primary',
    fn: null,
    show: false
  },
  {
    text: '委托他人',
    className: 'white',
    code: 'entrust',
    type: '',
    flag: 2,
    fn: null,
    show: false
  },
  {
    text: '驳回',
    className: 'white red',
    code: 'reject',
    type: '',
    flag: 3,
    fn: null,
    show: false
  },
  {
    text: '通过',
    className: 'blue',
    code: 'pass',
    type: 'primary',
    flag: 3,
    fn: null,
    show: false
  }
]
export const btns = [
  {
    name: '发起提案',
    type: 'primary',
    path: 'initiateProposal',
    params: 'add',
    badge: false
  },
  {
    name: '我发起的',
    type: 'success',
    path: 'initiate',
    params: '',
    badge: false
  },
  {
    name: '我审批的',
    type: 'warning',
    path: 'approval',
    params: '',
    badge: false
  }
]
export const btnData = [
  {
    type: 'contribute',
    list: [
      {
        name: '发起提案',
        type: 'primary',
        path: 'team-initiateProposal',
        params: 'add',
        badge: false
      },
      {
        name: '我发起的',
        type: 'success',
        path: 'team-initiate',
        params: '',
        badge: false
      },
      {
        name: '我审批的',
        type: 'warning',
        path: 'team-approval',
        params: '',
        badge: false
      }
    ]
  },
  {
    type: 'customize1',
    list: [
      {
        name: '发起提案',
        type: 'primary',
        path: 'customer-initiateProposal',
        params: 'add',
        badge: false
      },
      {
        name: '我发起的',
        type: 'success',
        path: 'customer-initiate',
        params: '',
        badge: false
      },
      {
        name: '我审批的',
        type: 'warning',
        path: 'customer-approval',
        params: '',
        badge: false
      }
    ]
  },
  {
    type: 'customize2',
    list: [
      {
        name: '发起提案',
        type: 'primary',
        path: 'customer2-initiateProposal',
        params: 'add',
        badge: false
      },
      {
        name: '我发起的',
        type: 'success',
        path: 'customer2-initiate',
        params: '',
        badge: false
      },
      {
        name: '我审批的',
        type: 'warning',
        path: 'customer2-approval',
        params: '',
        badge: false
      }
    ]
  }
]
