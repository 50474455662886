import * as http from './http'
//当月使用积分提交
export const postCustomerScoreSave = params => http.post('/customer-score/save', params)
//查询当前用户自定义积分
export const getCustomerScoreCountData = params => http.post('/customer-score/count-data', params)
//查询当前用户积分历史
export const getScorehistorylist = params =>
  http.post('/customer-score/list-history', params, { loading: false })
//查询当前用户各状态的记录统计
export const getUserStatus = params => http.post('/customer-score/list-count', params)

//审批说明查询 /contribute/score/detail-approve-desc
export const getApproveDesc = params => http.post('/contribute/score/detail-approve-desc', params)
//填写说明内容配置查询 /contribute/score/detail-submit-desc
export const getSubmitDesc = params => http.post('/contribute/score/detail-submit-desc', params)

//回显草稿箱数据
export const getDataDraft = params => http.post('/customer-web/data-draft', params)
//自定义删除
export const postCustomerDelete = params => http.post('/customer-web/delete', params)
//根据自定义查询详情
export const getCustomerDetail = params => http.post('/customer-web/detail', params)
//自定义提案编辑提交
export const postCustomerEdit = params => http.post('/customer-web/edit', params)
// 查询自定义-看看大家的创新提案
export const getCustomer = params => http.post('/customer-web/list', params)
//  查询自定义列表-我发起的
export const getMeSponsor = params => http.post('/customer-web/list-me-sponsor', params)

//查询自定义列表接口我审批的
export const getMeApprove = params => http.post('/customer-web/list-me-approve', params)
//查询自定义列表-接口条数
export const getListCount = params => http.post('/customer-web/list-mine-count', params)
//自定义提案新增
export const postCustomerAdd = params => http.post('/customer-web/save', params)

//保存草稿箱数据
export const postDataDraftSave = params => http.post('/customer-web/save-draft', params)

//创新应用层面列表查询
export const getCustomerBedding = params => http.post('/contribute/form/list-bedding', params)
//创新应用类别列表查询
export const getCustomerCategory = params => http.post('/contribute/form/list-category', params)

//审批或者驳回
export const postCustomerApprove = params => http.post('/customer-process/approve', params)
//根据自定义主键查询审批流程操作
export const getCustomerDetailProcess = params =>
  http.post('/customer-process/detail-process', params)
//审批委托提交
export const postCustomerEntrust = params => http.post('/customer-process/entrust', params)
//查询审批流程模板列表
export const getCustomerProcessList = params => http.post('/customer-process/list', params)
//撤销
export const postProcessRevoke = params => http.post('/customer-process/revoke', params)
// 根据名称搜索组织结构
export const getOrgList = params => http.post('/org/query-by-name', params)
// 查询成果级别
export const getResultlevelList = params => http.post('/contribute/score/list-result-level', params)
//查询表单字段 /Customer/form/list-web-form
export const getFromList = params => http.post('/contribute/form/list-web-form', params)
// 自定义维度表单配置
export const getformDetail = params => http.post('/customerForm/detail', params)
//获取指定维度得分
export const getMaxScore = params => http.post('/dimension/score', params)
