<template>
  <div class="proposal">
    <div v-for="(item, index) in data" :key="index" @click="toPage(item)">
      <div class="proposal-item">
        <img src="@/assets/images/word.svg" class="document" />
        <span class="item-name">{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'proposal-list',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    modelCode: {
      type: String,
      default: 'innovate'
    }
  },
  methods: {
    toPage(data) {
      let path = 'all'
      if (this.modelCode === 'team') {
        path =
          sessionStorage.getItem('code') === 'contribute'
            ? 'team-all'
            : sessionStorage.getItem('customize1')
            ? 'customer-all'
            : 'customer2-all'
      }
      let id =
        this.modelCode === 'innovate'
          ? data.innovateCode
          : sessionStorage.getItem('code') === 'contribute'
          ? data.contributeCode
          : data.customCode
      this.$router.push({
        name: path,
        query: {
          id: id,
          type: 'channel',
          modelCode: this.modelCode
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.proposal {
  overflow-y: auto;
  cursor: pointer;
  .proposal-item {
    margin-left: 23px;
    margin-right: 17px;
    height: 64px;
    line-height: 64px;
    box-shadow: 0px 1px 0px 0px #eeeeee;
    display: flex;
    align-items: center;
    @include bg-hover-color();
    .document {
      margin-left: 5px;
      margin-right: 15px;
    }
    .item-name {
      font-size: 14px;

      font-weight: 400;
      color: $lightblack;
    }
  }
}
</style>
