<!--
 * @Author: your name
 * @Date: 2021-03-05 09:05:54
 * @LastEditTime: 2021-04-22 10:50:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /pfc-pc/src/components/initiate/content-button.vue
-->
<template>
  <div class="buttons">
    <el-button class="el-btn" :type="item.type" @click="toPage(item.path, item.params)" v-for="(item, index) in list" :key="index">
      <i class="iconfont iconblue userIcon" :class="index === 1 ? 'iconfont icongreen' : index === 2 ? 'iconfont iconorange' : ''"
        ><span class="text">{{ item.name }}</span></i
      >
      <i class="icon-red" v-if="item.badge"></i>
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'content-button',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    toPage(path, param) {
      if (param === 'add') {
        this.$router.push({
          name: path,
          query: {
            type: param
          }
        })
      } else {
        this.$router.push({
          name: path,
          query: {}
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  .el-btn {
    height: 32px;
    position: relative;
    padding: 8px 16px;
    border-radius: 2px;
    opacity: 0.99;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    .userIcon {
      font-size: 14px;
    }
    .text {
      margin-left: 7px;
    }
    .icon-red {
      position: absolute;
      top: -3px;
      right: -2px;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50px;
      background: $red;
    }
  }
}
</style>
