<template>
  <div class="grade">
    <div class="grade-content">
      <div class="left">{{ data.date | formateDate(data.year) }}</div>
      <div class="right">
        <div>
          <span
            class="number"
            :class="data.score && data.score.slice(0, 1) === '+' ? 'blue' : ''"
            >{{ data.score | formateVal }}</span
          >
          <span class="text" :class="data.score && data.score.slice(0, 1) === '+' ? 'blue' : ''">{{
            data.score | formateVal(2)
          }}</span>
          <span
            class="text grade"
            :class="data.score && data.score.slice(0, 1) === '+' ? 'blue' : ''"
            >{{ data.score | formateVal(1) }}</span
          >
          <div
            class="text grade"
            :class="data.score && data.score.slice(0, 1) === '+' ? 'blue' : ''"
          >
            {{ data.remarks }}
          </div>
        </div>
        <!-- <div>
          
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'history-grade-list',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  filters: {
    formateVal(val, len) {
      if (len === 1) {
        return '分' + val.split('分')[1]
      } else if (len === 2) {
        const reg = /(\d+(\.\d+)?)/
        let reward = val.match(reg)[0]
        return reward
      } else {
        return val.substring(0, 1)
      }
    },
    formateDate(val, year) {
      let currentYear = new Date().getFullYear() + ''
      if (year === currentYear) {
        return val
      } else {
        return `${year}年${val}`
      }
    }
  }
}
</script>

<style scoped lang="scss">
.grade {
  overflow-y: scroll;
  flex: 1;
  cursor: pointer;
  @include bg-hover-color();
  .grade-content {
    border-bottom: 1px solid $linecolor;
    display: flex;
    align-items: center;
    min-height: 64px;
    .left {
      flex: 1;
      font-size: 16px;
      font-weight: 400;
      color: $lightblack;
      line-height: 16px;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
      .number {
        font-size: 16px;
        font-weight: 500;
        color: $red;
        // line-height: 14px;
      }

      .text {
        font-size: 18px;
        font-weight: 400;
        color: $red;
        //line-height: 12px;
      }
      .grade {
        font-size: 12px;
      }
      .blue {
        color: $blue;
      }
    }
  }
}
</style>
