import * as http from './http'
//  查询团队贡献列表-我发起的
export const getMeSponsor = (params, path = '/contribute/web/list-me-sponsor') =>
  http.post(path, params)
// 查询团队贡献-看看大家的创新提案
export const getInnovation = (params, path = '/contribute/web/list') => http.post(path, params)

//查询团队贡献列表接口我审批的
export const getMeApprove = (params, path = '/contribute/web/list-me-approve') =>
  http.post(path, params)

//当月使用积分提交
export const postTeamScoreSave = (params, path = '/contribute/index/save') =>
  http.post(path, params)
//查询当前用户团队贡献积分
export const getTeamScoreCountData = (params, path = '/contribute/index/count-data') =>
  http.post(path, params)
//查询当前用户积分历史
export const getScorehistorylist = (params, path = '/contribute/index/list-history') =>
  http.post(path, params, { loading: false })

//审批说明查询 /contribute/score/detail-approve-desc
export const getApproveDesc = (params, path = '/contribute/score/detail-approve-desc') =>
  http.post(path, params)
//填写说明内容配置查询 /contribute/score/detail-submit-desc
export const getSubmitDesc = (params, path = '/contribute/score/detail-submit-desc') =>
  http.post(path, params)

//回显草稿箱数据
export const getDataDraft = (params, path = '/contribute/web/data-draft') => http.post(path, params)
//保存草稿箱数据
export const postDataDraftSave = (params, path = '/contribute/web/save-draft') =>
  http.post(path, params)
//根据团队贡献查询详情
export const getInnovationDetail = (params, path = '/contribute/web/detail') =>
  http.post(path, params)
//团队贡献提案编辑提交
export const postInnovationEdit = (params, path = '/contribute/web/edit') => http.post(path, params)
//团队贡献提案新增
export const postInnovationAdd = (params, path = '/contribute/web/save') => http.post(path, params)
//团队贡献删除
export const postInnovationDelete = (params, path = '/contribute/web/delete') =>
  http.post(path, params)
//创新应用层面列表查询
export const getInnovationBedding = (params, path = '/contribute/form/list-bedding') =>
  http.post(path, params)
//创新应用类别列表查询
export const getInnovationCategory = (params, path = '/contribute/form/list-category') =>
  http.post(path, params)

//审批或者驳回
export const postInnovationApprove = (params, path = '/contribute/process/approve') =>
  http.post(path, params)
//根据团队贡献主键查询审批流程操作
export const getInnovationDetailProcess = (params, path = '/contribute/process/detail-process') =>
  http.post(path, params)
//审批委托提交
export const postInnovationEntrust = (params, path = '/contribute/process/entrust') =>
  http.post(path, params)
//查询审批流程模板列表
export const getInnovationProcessList = (params, path = '/contribute/process/list') =>
  http.post(path, params)
//撤销
export const postProcessRevoke = (params, path = '/contribute/process/revoke') =>
  http.post(path, params)
// 根据名称搜索组织结构
export const getOrgList = params => http.post('/org/query-by-name', params)
// 查询成果级别
export const getResultlevelList = (params, path = '/contribute/score/list-result-level') =>
  http.post(path, params)
//查询表单字段 /innovation/form/list-web-form
export const getFromList = (params, path = '/contribute/form/list-web-form') =>
  http.post(path, params)
//查询列表条数
export const getListCount = (params, path = '/contribute/web/list-mine-count') =>
  http.post(path, params)
//查询提案类别列表
export const getListCategory = params =>
  http.post('/contribute/score/list-contribute-category', params)
//查询贡献级别
export const getcontributeQueryResultLevel = params =>
  http.post('/contribute/score/query-result-level', params)
