<template>
  <div class="integral-list">
    <div class="box">
      <div class="current">
        <div class="num">
          <span class="comm">{{ sumScore || 0 }}</span>
          <span class="grade">分</span>
        </div>
        <div class="text">
          积分池
        </div>
      </div>
      <div class="line"></div>
      <div class="month">
        <div class="num">
          <span class="comm">{{ maxScore || 0 }}</span>
          <span class="grade">分</span>
        </div>
        <div class="text">{{ tip }}</div>
      </div>
    </div>
    <div class="use">
      <!-- 本月使用 -->
      <div class="month-use" v-show="usageMode">{{ useVal }}</div>
      <div class="current-use" v-show="usageMode">
        <el-select
          v-model="currentUserScore"
          class="use-sel"
          :disabled="!isOpenButton"
          placeholder="请选择"
          @visible-change="handleSel"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button type="primary" class="use-btn" @click="handleConfirm" :disabled="!isOpenButton"
          >确定</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getScoreCountData, postScoreSave } from '@/api/api_innovation'
import { getTeamScoreCountData, postTeamScoreSave } from '@/api/api_team'
import { getCustomerScoreCountData, postCustomerScoreSave } from '@/api/api_customer'
import { MessageBox, Message } from 'element-ui'
export default {
  name: 'integral-list',
  props: {
    code: {
      type: String,
      defauult: 'contribute'
    },
    modelType: {
      type: String,
      default: 'initiate' //team 团队贡献
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      maxScore: 0, //本月最多可使用积分数
      currentUserScore: 0, //本月使用积分数
      sumScore: 0, //总积分,
      isOpenButton: false, //是否开启提交按钮
      options: [],
      usageMode: true, //1手动 2自动
      tip: '本月最多可用',
      useVal: '本月使用',
      selTip: '',
      assessDate: null
    }
  },
  created() {
    this.getList()
  },
  methods: {
    handleSel(val) {
      if (['innovate', 'contribute'].includes(sessionStorage.getItem('code'))) {
        if (val && !this.options.length) {
          this.$warning(this.selTip)
        }
      }
    },
    /**
     * 接口调用
     * modelType initiate=创新成果 team=团队贡献
     */
    async handleListApi(params, type = 'list') {
      if (this.modelType === 'initiate') {
        if (type === 'list') {
          //列表
          return await getScoreCountData(params)
        } else {
          //提交
          return await postScoreSave(params)
        }
      } else {
        if (type === 'list') {
          //列表
          // let path = '/customer-score/count-data'
          if (sessionStorage.getItem('code') === 'contribute') {
            return await getTeamScoreCountData(params)
          } else {
            let param = {
              ...params,
              dimensionCode: sessionStorage.getItem('code')
            }
            return await getCustomerScoreCountData(param)
          }
        } else {
          if (sessionStorage.getItem('code') === 'contribute') {
            return await postTeamScoreSave(params)
          } else {
            let param = {
              ...params,
              dimensionCode: sessionStorage.getItem('code')
            }
            return await postCustomerScoreSave(param)
          }
        }
      }
    },
    getList() {
      let params = {}
      this.handleListApi(params)
        .then(res => {
          //currentUserScore	本月使用积分数
          //maxScore	本月最多可使用积分数
          //sumScore	总积分
          let {
            assessDateScore,
            currentUserScore,
            maxScore,
            sumScore,
            isOpenButton,
            month,
            assessDate = null,
            webScoreUseOutput
          } = res.data
          this.isOpenButton = isOpenButton ? true : false
          this.maxScore = parseFloat(maxScore) || 0
          this.sumScore = parseFloat(sumScore) || 0
          //创新/团队贡献
          if (['innovate', 'contribute'].includes(sessionStorage.getItem('code'))) {
            let { usageMode, floatStr } = webScoreUseOutput
            this.isOpenButton = true
            this.assessDate = assessDate
            let assessIsOver = res.data.assessIsOver
            if (Object.keys(webScoreUseOutput).length) {
              this.usageMode = usageMode === 1 ? true : false
              this.tip = this.usageMode ? `${month}月已用` : `${month}月已自动使用`
              this.maxScore = floatStr
              this.useVal = `请选择${month}月使用分数`
              // currentUserScore	本月使用积分数（弃用）
              // assessDateScore	当前考核月总积分
              //floatStr 本月已使用
              //maxScore本月最多可使用积分数(固定)
              //1.无可用分数
              if (assessIsOver) {
                this.selTip = `${month}月考评已结束`
                this.currentUserScore = null
              } else if (Number(floatStr) >= Number(maxScore)) {
                // 使用分数已达到用分限制时--X月最多可用2.5分
                this.selTip = `${month}月最多可用${maxScore}分`
                this.currentUserScore = null
              } else if (!Number(assessDateScore)) {
                //  不管积分池是否有余额，只要这些余额是不能用于5月的---积分池尚无5月可用的分数
                this.selTip = `积分池尚无${month}月可用的分数`
                this.currentUserScore = null
              } else {
                //2有分数，判断范围
                // maxScore - floatStr == assessDateScore取小
                this.currentUserScore = null
                let max =
                  maxScore - Number(floatStr) < Number(assessDateScore)
                    ? maxScore - Number(floatStr)
                    : Number(assessDateScore)
                // let max = 1.6
                if (max < 1) {
                  this.options.push({
                    value: max,
                    label: max + '分'
                  })
                  max = 0
                } else {
                  if (!Number.isInteger(max)) {
                    let val = String(max).split('.')
                    let maxVal = max
                    max = Math.floor(max)
                    if (val[1] && val[1] > 0) {
                      //小数位>0,拼接到下拉框
                      this.options.push({
                        value: maxVal,
                        label: maxVal + '分'
                      })
                    }
                  }
                }
                for (let i = max; i >= 0; i--) {
                  this.options.push({ value: i, label: i + '分' })
                }
              }
            }
          } else {
            if (this.isOpenButton) {
              this.currentUserScore = this.maxScore < this.sumScore ? this.maxScore : this.sumScore
              let max = this.currentUserScore
              if (!Number.isInteger(this.currentUserScore)) {
                let val = String(this.currentUserScore).split('.')
                max = Math.floor(this.currentUserScore)
                if (val[1] && val[1] > 0) {
                  //小数位>0,拼接到下拉框
                  this.options.push({
                    value: this.currentUserScore,
                    label: this.currentUserScore + '分'
                  })
                }
              }
              for (let i = max; i >= 0; i--) {
                this.options.push({ value: i, label: i + '分' })
              }
            } else {
              this.currentUserScore = parseFloat(currentUserScore)
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleConfirm() {
      if (['innovate', 'contribute'].includes(sessionStorage.getItem('code'))) {
        if (this.options.length && typeof this.currentUserScore !== 'object') {
          this.handleSubmit()
        } else {
          this.$warning(this.selTip)
        }
      } else {
        MessageBox.confirm(`确定后本月不再允许修改,确定使用${this.currentUserScore}分？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.handleSubmit()
          })
          .catch(() => {
            Message.info(`已取消操作`)
          })
      }
    },
    handleSubmit() {
      let params = {
        currentMonth: this.assessDate,
        score: this.currentUserScore
      }
      if (typeof this.currentUserScore === 'object') {
        return false
      }
      this.handleListApi(params, 'add')
        .then(res => {
          if (res.code === '000000') {
            this.options = []
            this.getList()
            this.$parent.list = []
            this.$parent.getList(1)
          }
        })
        .catch(err => console.log(err))
    }
  }
}
</script>

<style scoped lang="scss">
.integral-list {
  display: flex;
  align-items: center;
  flex: auto;
  .box {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 30px;
  }
  .use {
    flex: 1;
    //margin-left: 46px;
  }
  .line {
    width: 1px;
    height: 47px;
    background: $linecolor;
  }
  .num {
    line-height: 12px;
    .comm {
      font-size: 28px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $lightblack;
      line-height: 23px;
    }
  }
  .text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: $darkgrey;
    line-height: 14px;
  }
  .use {
    .month-use {
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: 400;
      color: $lightblack;
      line-height: 16px;
    }
    .current-use {
      height: 32px;
      width: 188px; //268
      display: flex;
      .use-btn {
        height: 100%;
        width: 62px;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
      }
      .use-sel {
        height: 100%;
        width: calc(100% - 62px);
        /deep/.el-input__inner {
          height: 32px;
          line-height: 32px;
        }
        /deep/.el-input__icon {
          line-height: 32px;
        }
      }
    }
  }
}
</style>
